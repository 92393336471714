<template>
  <div class="movies-bg">
    <div class="container ">
      <div class="row justify-content-center">
        <div class="accordion accordion-flush col-10 col-sm-9 col-md-8" id="accordionFlush">
          <app-section :title="$t('FAQs')" class="my-5" :bootom_blue_line="true"></app-section>      
          <div class="accordion-items my-5" v-if="faqs">
            <div class="accordion-item" v-for="(f,i) in faqs" v-bind:key="i">
              <div class="accordion-header" :id="'flush-heading-'+i">
                <button @click="toggleSelect(i)" class="accordion-button text-left collapsed btn w-100"
                        type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapse-'+i" aria-expanded="false"
                        :aria-controls="'flush-collapse-'+i" >{{f.question}} <i class="bi float-right" v-bind:class="{'bi-x-lg': selected === i, 'bi-plus-lg': selected !== i}"></i>
                </button>
              </div>
              <div :id="'flush-collapse-'+i" class="accordion-collapse px-3" v-bind:class="{'collapse': i !== selected}" :aria-labelledby="'flush-heading-'+i" data-bs-parent="#accordionFlush">
                <div class="accordion-body" v-html="f.answer"></div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import AppSection from "@/components/AppSection";

export default {
  name: "Faqs",
  components: {
    AppSection 
  },
  data(){
    return {
      selected: null
    }
  },
  computed:{
    ...mapGetters(['faqs'])
  },
  created(){
    this.getFaqs()
  },
  methods:{
    toggleSelect(index){
      if( this.selected == index){
        this.selected = null;
      }else{
        this.selected = index;
      }
    },
    getFaqs(){
      this.$store.dispatch("fetchFaqs");
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/styles/colors";
.accordion-item{
  padding: .18em;
  padding-bottom: 0.5em;
  button{
    border-radius: 0;
    border-color: $light-blue;
    padding: 0.6em 0.75em;
    &:focus{
      box-shadow: none;
    }
  }
  .accordion-collapse{
    border: 1px solid $light-blue;
    margin-top: 0.1em;
    margin-bottom: 0.9em;
    padding: 0.8em 0.75em;    
  }
  //border: 1px solid $light-blue;
}
.accordion-items{
  padding: .7em;
}

.movies-bg{
  min-height: 60vh;
  background-color: $medium-blue-2;
}
</style>